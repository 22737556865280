.loader-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-light);
    font-size: 5rem;
    color: var(--color-primary);
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.three-quarter-spinner {
    border: 3px solid var(--color-primary);
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
    width: 1em;
    height: 1em;

    &.white {
        border: 3px solid var(--color-light);
        border-top: 3px solid transparent;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}
