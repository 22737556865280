.calendar {
    --event-color: var(--color-primary);
    --fc-today-bg-color: rgba(var(--rgb-secondary), 0.1);

    //Reset
    .fc-button-primary:not(:disabled):active,
    .fc-button-primary:not(:disabled).fc-button-active,
    .fc-button-primary:not(:disabled):active:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc-button-primary:focus {
        box-shadow: none !important;
        background-color: transparent !important;
    }
    .fc-button-primary:disabled {
        background-color: transparent !important;
    }
    .fc-theme-standard th {
        border: none;
    }
    .fc-col-header-cell,
    .fc-timegrid-axis,
    .fc-scrollgrid,
    .fc-scrollgrid-section-header,
    .fc-scrollgrid-section-header td {
        border: none !important;
    }
    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
        box-shadow: none;
    }
    .fc-v-event {
        border: none;
        background-color: transparent;
    }
    .fc-timegrid-event .fc-event-main {
        padding: 0;
    }

    //Calendar
    .fc-timegrid-slot-minor {
        border-top: 0;
    }
    .fc-timegrid-slot-label {
        border-top: 0;
    }
    .fc-scrollgrid > tbody {
        background-color: var(--color-primary);

        & > tr > td {
            border: none;
        }

        .fc-scroller {
            border-top-left-radius: 10px;
            overflow: hidden;
            background-color: var(--color-light);
        }
    }

    //Header toolbar
    .fc-header-toolbar {
        background-color: var(--color-primary);
        padding: space(0) space(2);
        color: var(--color-light);
        margin-bottom: 0 !important;

        .fc-toolbar-title {
            font-size: 1rem;
            font-weight: 500;
        }

        .fc-button-primary {
            padding: space(2) 0;
            background-color: transparent;
            border: none;
            font-weight: 500;
        }

        .fc-toolbar-chunk:last-child .fc-button {
            margin-left: space(2);
        }
    }

    //Footer toolbar
    .fc-footer-toolbar {
        background-color: var(--color-primary);
        padding: 0 space(2);
        color: var(--color-light);
        margin-top: 0 !important;
        border-top-left-radius: 10px;

        .fc-button-primary {
            padding: space(2) 0;
            background-color: transparent;
            border: none;
            font-weight: 500;
        }

        .fc-toolbar-chunk:first-child .fc-button {
            opacity: 0.5;
            margin-right: space(2);

            &.fc-button-active {
                opacity: 1;
            }
        }
        .fc-toolbar-chunk:last-child .fc-button {
            margin-left: space(2);
        }
    }

    //Calendar day header
    .fc-col-header {
        background: var(--color-primary);
    }
    .fc-col-header-cell {
        color: var(--color-white);
        padding-bottom: space(1);
    }
    a.fc-col-header-cell-cushion {
        width: 100%;
    }
    .calendar__dayHeader {
        display: flex;
        flex-direction: column;
        color: var(--color-light);

        .day {
            font-size: 0.75rem;
        }

        &.today {
            color: var(--color-secondary);
        }
        &.today .date {
            position: relative;
            color: var(--color-light);
            z-index: 10;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: var(--color-secondary);
                border-radius: 100%;
                width: 24px;
                height: 24px;
                z-index: -1;
            }
        }
    }

    //Icons
    .fc-icon {
        &.fc-icon-refresh {
            background-image: url("../../assets/icons/refresh.svg");
        }
        &.fc-icon-today {
            background-image: url("../../assets/icons/calendar-today.svg");
        }
        &.fc-icon-notification {
            background-image: url("../../assets/icons/notification.svg");
            position: relative;

            &.badge::after {
                content: "";
                position: absolute;
                right: -3px;
                top: 0;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: var(--color-secondary);
            }
        }
    }

    //Label
    .fc-timegrid-axis-cushion,
    .fc .fc-timegrid-slot-label-cushion {
        font-size: 0.75rem;
        position: relative;
        opacity: 0.8;
        transform: translateY(-80%);
    }

    //Events
    .calendar__event {
        box-shadow: none !important;
        font-size: 0.75rem;
        background-color: var(--color-primary);
        border: none;
        border-radius: 4px;
        height: 100%;
        padding: 2px;

        p {
            position: sticky;
            top: 0;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            padding: 4px 0;
            font-weight: 500;
        }
    }
}
