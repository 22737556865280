.notifications-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(var(--rgb-primary), 0.8);
    }
}

.notifications-list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 220px;
    background-color: var(--color-light);
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.3s ease;

    &__header,
    &__footer {
        padding: space(3);
        position: sticky;
        top: 0;
        background-color: var(--color-light);
        width: 100%;
    }

    &__footer {
        position: absolute;
        bottom: 0;
        top: auto;
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}

.notification {
    padding: space(2) space(3);
    border-bottom: 1px solid rgba(var(--rgb-primary), 0.2);
    width: 100%;

    &.is-not-seen {
        font-weight: 500;
        background-color: rgba(var(--rgb-secondary), 0.1);
    }

    &:last-child {
        border-bottom: none;
    }
}

.notifications-wrapper.is-open {
    opacity: 1;
    pointer-events: all;

    .notifications-list {
        transform: translateX(0);
    }
}
