// Spaces
// =============
@for $i from 0 through 5 {
    body {
        .p-#{$i} {
            padding: space($i);
        }

        .pt-#{$i} {
            padding-top: space($i);
        }

        .pb-#{$i} {
            padding-bottom: space($i);
        }

        .px-#{$i} {
            padding-left: space($i);
            padding-right: space($i);
        }

        .py-#{$i} {
            padding-top: space($i);
            padding-bottom: space($i);
        }

        .my-#{$i} {
            margin-top: space($i);
            margin-bottom: space($i);
        }

        .m-#{$i} {
            margin: space($i);
        }

        .mx-#{$i} {
            margin-left: space($i);
            margin-right: space($i);
        }

        .mt-#{$i} {
            margin-top: space($i);
        }

        .mb-#{$i} {
            margin-bottom: space($i);
        }

        .mr-#{$i} {
            margin-right: space($i);
        }

        .ml-#{$i} {
            margin-left: space($i);
        }
    }
}
