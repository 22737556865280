.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;
    padding: space(5);

    .modal {
        z-index: 100;
        background: var(--color-light);
        position: relative;
        margin: auto;
        border-radius: var(--border-radius);
        animation: 0.3s ease slideIn;
        padding: space(3);
        width: 100%;
    }

    .modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: var(--vh100);
        background-color: rgba(var(--rgb-primary), 0.6);
        animation: 0.3s ease fadeIn;
    }

    &.hide {
        .modal__overlay {
            pointer-events: none;
            animation: 0.3s ease forwards fadeOut;
        }
        .modal {
            animation: 0.3s ease forwards slideOut;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.modal__header {
    padding: space(4);
    background-color: var(--color-white);
    position: sticky;
    top: 0;
}
