.login {
    padding-left: space(3);
    padding-right: space(3);

    .logo {
        margin-bottom: space(5);

        img {
            height: 150px;
            width: 100%;
            object-fit: contain;
        }
    }
}
