.text-md {
    font-size: 1.125rem;
    font-weight: 500;
}

.text-error {
    color: var(--color-secondary);
}

.text-bold {
    font-weight: 700;
}
.text-capitalize {
    text-transform: capitalize;
}
