.btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: var(--color-primary);
    color: var(--color-light);
    width: 100%;
    text-align: center;
    padding: space(2) space(4);
    position: relative;
    border-radius: var(--border-radius);

    &.btn-outline {
        background-color: transparent;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
    }

    .spinner-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .three-quarter-spinner {
        width: 1rem;
        height: 1rem;
    }
}

.btn:disabled {
    color: var(--color-primary);
}
