// Variables
// ==================
:root {
    // --color-dark: #14141b;

    --color-white: #ffffff;
    --color-light: #f7f7f8;
    --color-primary: #1b2631;
    --color-secondary: rgb(231, 76, 60);
    // --color-tertiary: #ecf0f1;

    --rgb-primary: 44, 62, 80;
    --rgb-secondary: 255, 40, 40;

    --font: 'Roboto', sans-serif;

    --border-radius: 8px;

    --space: 0.5rem;
    --container-padding: calc(var(--space) * 1);
    --container-width: calc(100vw - var(--container-padding) * 2);
    --max-width: 1200px;
    --vh100: calc(var(--vh, 1vh) * 100);
}

@include up(768) {
    :root {
        --container-padding: calc(var(--space) * 12);
    }
}
