.form-group {
    label {
        font-weight: bold;
    }
    .form-control {
        width: 100%;
        background-color: var(--color-white);
        border: 1px solid rgba(var(--rgb-primary), 0.5);
        border-radius: var(--border-radius);
        padding: space(1) space(2);
        transition: border 0.2s ease;

        &.error {
            border: 1px solid var(--color-secondary);
        }
    }
    .form-control:focus {
        border: 1px solid var(--color-primary);
        outline: none;
    }

    .input-icon {
        position: relative;
        overflow: hidden;

        .icon {
            position: absolute;
            display: flex;
            align-items: center;
            right: 0;
            top: 2px;
            bottom: 2px;
            padding: 0 space(2);
        }

        svg {
            font-size: 1rem;
            color: rgba(var(--rgb-primary), 0.5);
        }

        &:focus-within {
            svg {
                color: var(--color-primary);
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .input-checkbox {
        display: flex;
        align-items: center;

        label,
        p {
            margin-left: space(2);
            font-weight: normal;
            font-family: var(--font);
            text-transform: capitalize;
        }

        &.disabled {
            pointer-events: none;
        }
    }

    &.error {
        label {
            color: var(--color-secondary);
        }
        .form-control {
            border: 1px solid var(--color-secondary);
        }
        .form-control:focus {
            border: 1px solid var(--color-secondary);
        }
    }
}
